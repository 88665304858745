@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.generic-icon {
  max-width: 100px;
  max-height: 40px;
  filter: grayscale(100%); /* Make the image grayscale */
  transition: filter 0.3s ease; /* Smooth transition for the filter */
}

.generic-icon:hover {
  filter: none; /* Remove the filter on hover to show colors */
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.software-skill-inline > i {
  color: $subTitle;
}

.software-skill-inline > i:hover {
  color: $skillsColor;
  transition: filter 0.3s ease; /* Smooth transition for the filter */
}
.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}
.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
  transition: filter 0.3s ease; /* Smooth transition for the filter */
}
